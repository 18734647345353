import '../../Admin/style.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserApiClient from '../../ApiClients';
import { UserContext } from '../../TabContextProvider';

function Login() {

  const {User, setUser} = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await UserApiClient.post('api/admin-login/', formData).then( async res =>{
      let data = await res.data
      if (res.success && data) {
        setUser(data.user);
        localStorage.setItem('token', data.access);
        localStorage.setItem('userId', data.user.id);
        navigate('/all-user');
      } else {
        console.log(data.detail)
      }
    })
  };


  return (
    <>
      <div className="row aduser-login">
      <div className='col-sm-0 col-md-3 col-lg-5 col-xl-6 logo-set'>
          <div className='logo-img'><img src="/assets/Layer_1.png" className="logo" alt="logo" style={{width: "50%"}}/></div>
        </div>
        <div className='col-6'>
          <div className='signin-set'>
            <div className='signin'>
              <h2>ADMIN LOGIN</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input type="email" className="form-control" onChange={handleChange} name='email' placeholder="Enter your email address" required/>
                </div>
                <select className="form-select form-select-lg mb-3" style={{fontWeight: "400", fontSize: "1rem"}} required>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
                <div className="form-group">
                  <input type="password" className="form-control" onChange={handleChange} name='password' placeholder="Enter your Password" required/>
                </div>
                <button type="submit" className="btn btn-primary signin-btn mt-4">Log In</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
