import "../../style.css"
import React from 'react';


function Security() {

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <h5>Change Password</h5>
                        <form className='my-5'>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="firstName">Current Password</label>
                                    <input type="text" id="firstName" className="form-control" placeholder="First name" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="firstName">New Password</label>
                                    <input type="text" id="firstName" className="form-control" placeholder="New Password" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="firstName">Confirm New Password</label>
                                    <input type="text" id="firstName" className="form-control" placeholder="ReEnter New Password" />
                                </div>
                            </div>
                        </form>
                        <h5>Password Requirements:</h5>
                        <span> - Minimum 8 Characters long - the more, the better</span> <br/>
                        <span> - At least one lowercase Character</span> <br/>
                        <span> - At least one number, symbol or whitespace Character</span> <br/>
                        <div className="row mt-5">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <button type="button" className="btn btn-primary" style={{width: "100%"}}>Save Changes</button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <button type="button" className="btn btn-secondary" style={{width: "100%"}}>Reset</button>
                                </div>
                            </div>

                    </div>
                </div>
                
            </div>

        </>
    );
}

export default Security;
