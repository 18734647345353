import '../App.css';
import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';
import Swal from 'sweetalert2';


function Header({chatbotName}) {

  const navigate = useNavigate();
  const {User, setUser} = useContext(UserContext);

  const handleLogout = function (e) {
    Swal.fire({
      title: "Logout Successfull.",
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })
    setUser({})
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/')
  };
      
    const maindash = () => {
        navigate('/');
      };
    const pathname = window.location.pathname;

  return (
    <>
      <div className="header-sec">
        <div className='headerlogo-sec' onClick={maindash}>
          <img src="/assets/Layer_1.png" className="logo mx-5" alt="logo" style={{ width: '80%' }} />
        </div>
        <div className="input-group">
          <div className='headname'>
            <h4>{pathname.includes('chat-bots')?"Dashboard": pathname.includes('layout')? "Chatbot": pathname.includes('help')? "Help" :pathname.includes('settings')?'Account Setting':''}</h4>
            <span className='text-capitalize' style={{color: '#acacac', fontSize: "18px"}}><small>{chatbotName||"Welcome to Nimibot"}</small></span>
          </div>
          <form className='searchbar'>
            <input type="text" placeholder="Search.." name="search" />
            <button type="submit"><i className="fa fa-search"></i></button>
          </form>
        </div>

        <div className='mobdrop'>
        <div className="dropdown" style={{ display: "flex" }}>
          <img src={User?.profile_pic||"/assets/profile-user.png"} alt="user-logo" style={{ width: "40px" }}></img>
          <button className="btn dropdown-toggle pe-4 text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            {`${User.first_name} ${User.last_name} `}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><NavLink className="dropdown-item" to="/chat-bots">Dashboard</NavLink></li>
            <li><NavLink className="dropdown-item" to="/settings">Account Setting</NavLink></li>
            <li><div className="dropdown-item cursor-pointer" href="#" onClick={handleLogout}>Log out</div></li>
          </ul>
        </div>
        </div>

        <div className='deskdrop'>
        <div className="dropdown" style={{ display: "flex" }}>
          <img src={User?.profile_pic||"/assets/profile-user.png"}  alt="user-logo" style={{ width: "40px" }}></img>
          <button className="btn dropdown-toggle pe-4 text-capitalize" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          {`${User.first_name} ${User.last_name} `} 
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><NavLink className="dropdown-item" to="/chat-bots">Dashboard</NavLink></li>
            <li><NavLink className="dropdown-item" to="/">Help</NavLink></li>
            <li><NavLink className="dropdown-item" to="/settings">Account Setting</NavLink></li>
            <li><div className="dropdown-item cursor-pointer" href="#" onClick={handleLogout}>Log out</div></li>
          </ul>
        </div>
        </div>

      </div>
    </>
  );
}

export default Header;
