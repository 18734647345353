import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UserApiClient from '../ApiClients';

const Help = ({ chatbotList }) => {

    const [data, setData] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleFromSubmit = async (e) => {
        e.preventDefault();
        console.log(data);
        const res = await UserApiClient.post(`bot/chatbot/help/`, {...data}, true);
        if (res.success) {
            console.log(res.data);
        }
    }


    return (
        <>
            <form onSubmit={handleFromSubmit}>
                <div className='m-5'>
                    <div className='help-outer'>
                        <h4><strong>Submit a case to our Customer Support Team</strong></h4>
                        <div className="form-group interface">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                maxLength={250}
                                placeholder="example@gmail.com"
                                required />
                        </div>
                        <div className="form-group interface">
                            <label htmlFor="exampleInputtext">Related Account</label>
                            <select className="form-select" name='related_account' onChange={handleChange} required>
                                <option value="">Select</option>
                                <option value="Webtunix Developers's team">Webtunix Developers's team</option>
                                <option value="Webtunix Developers's team A">Webtunix Developers's team A</option>
                                <option value="Webtunix Developers's team B">Webtunix Developers's team B</option>
                            </select>
                        </div>
                        <div className="form-group interface">
                            <label htmlFor="exampleInputtext">Related Chatbots</label>
                            <select className="form-select" name='chatbot' onChange={handleChange} required>
                                <option value="">Select</option>
                                {chatbotList && chatbotList.map((item, index) => (
                                    <option key={index} value={item.id}>{item.chatbot_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='row'>
                            <div className="col-6 form-group interface">
                                <label htmlFor="exampleInputtext">Selected Problem</label>
                                <select className="form-select" name='problem' onChange={handleChange} required>
                                    <option value="">Select</option>
                                    <option value="Billing">Billing</option>
                                    <option value="Account Management">Account Management</option>
                                    <option value="Feature Request">Feature Request</option>
                                    <option value="Bugs/Issues">Bugs/Issues</option>
                                    <option value="Affiliate Program">Affiliate Program</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="General Inquiries">General Inquiries</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="col-6 form-group interface">
                                <label htmlFor="exampleInputtext">Selected Severity</label>
                                <select className="form-select" name='severity' onChange={handleChange} required>
                                    <option value="">Select</option>
                                    <option value="Low">Low</option>
                                    <option value="Normal">Normal</option>
                                    <option value="High">High</option>
                                    <option value="Urgent">Urgent</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group interface">
                            <label htmlFor="title">Title</label>
                            <input type="text" className="form-control"
                                name="title"
                                id="title"
                                onChange={handleChange}
                                maxLength={250}
                                placeholder="Title"
                                required />
                        </div>
                        <div className="form-group interface">
                            <label htmlFor="description">Discription</label>
                            <textarea type="text" className="form-control"
                                name="description"
                                id="description"
                                onChange={handleChange}
                                placeholder="Please include all information relevant to your issue." />
                        </div>
                        <small>Please add a description before submitting your request.</small>
                        <div className='d-flex justify-content-end'>
                            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>Submit Request</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Help;
