import '../../App.css';
import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { FileUploader } from "react-drag-drop-files";
import Swal from 'sweetalert2';
import UserApiClient from '../../ApiClients';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Sources({ data }) {

    const { chatbotData, User } = data;

    const [value, setValue] = useState(0);
    const [Documents, setDocuments] = useState([]);
    const [Texts, setTexts] = useState([]);
    const [textHeading, setTextHeading] = useState('');
    const [textArea, setTextArea] = useState('');
    const [Websites, setWebsites] = useState([]);
    const [Qas, setQas] = useState([]);
    const [files, setFiles] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [urlFields, setUrlFields] = useState([]);
    const [detectedChars, setDetectedChars] = useState(0);
    const fileCheckboxesRef = useRef([]);
    const textCheckboxesRef = useRef([]);
    const urlCheckboxesRef = useRef([]);
    const qaCheckboxesRef = useRef([]);
    let isFetching = false;

    const handleFileChange = (fileList) => {
        setFiles(Array.from(fileList));
    };

    // Specify the allowed file types (adjust as needed)
    const fileTypes = ['pdf', 'docx', 'txt'];

    const handleDeleteField = (url) => {
        setUrlFields(urlFields.filter(field => field.url !== url));
    };
    const handleUrlChecked = (index, e) => {
        const newUrlFields = [...urlFields];
        newUrlFields[index]['checked'] = e.target.checked;
        setInputs(newUrlFields);
    };

    const addInputField = () => {
        setInputs([...inputs,
        {
            "text_heading": "",
            "question": "",
            "answer": ""
        }
        ]);
    };

    const deleteInputField = (index) => {
        setInputs(inputs.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        newInputs[index][name] = value;
        setInputs(newInputs);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleDelete = (e) => {
        const id = e.target.getAttribute('data-id');
        const type = e.target.getAttribute('data-type');
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (type == 'document') {
                    await UserApiClient.delete(`bot/document/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'text') {
                    await UserApiClient.delete(`bot/text/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'url') {
                    await UserApiClient.delete(`bot/website/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                } else if (type == 'qa') {
                    await UserApiClient.delete(`bot/qa/delete/${id}/`, {}, true).then(async res => {
                        await fetchData();
                        if (res.success) {
                            Swal.fire(res.data.message, '', 'success', 3000);
                        }
                    })
                }
            }
        });
    };


    async function handleMultipleDelete(e) {
        const ids = [];
        var apiEndpoint;
        if (value === 0) {
            fileCheckboxesRef.current.forEach(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.getAttribute('data-id'))
                }
            });
            apiEndpoint = 'document'
        } else if (value === 1) {
            textCheckboxesRef.current.forEach(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.getAttribute('data-id'))
                }
            });
            apiEndpoint = 'text'
        } else if (value === 2) {
            urlCheckboxesRef.current.forEach(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.getAttribute('data-id'))
                }
            });
            apiEndpoint = 'website'
        } else if (value === 3) {
            qaCheckboxesRef.current.forEach(checkbox => {
                if (checkbox.checked) {
                    ids.push(checkbox.getAttribute('data-id'))
                }
            });
            apiEndpoint = 'qa'
        }

        if (ids.length === 0 || !apiEndpoint) {
            Swal.fire('Please select at least a value.', '', 'error');
            return;
        }

        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await UserApiClient.delete(`bot/${apiEndpoint}/multi-delete/${chatbotData.id}/`, {ids: ids}, true);
                if (res.success) {
                    await fetchData();
                    Swal.fire(res.data.message, '', 'success', 3000)
                }
            }
        });
    }

    const handleMasterCheckboxChange = (e, type) => {
        const checked = e.target.checked;

        if (type == 'file') {
            fileCheckboxesRef.current.forEach(checkbox => {
                checkbox.checked = checked;
            });
        } else if (type == 'text') {
            textCheckboxesRef.current.forEach(checkbox => {
                checkbox.checked = checked;
            });
        } else if (type == 'url') {
            urlCheckboxesRef.current.forEach(checkbox => {
                checkbox.checked = checked;
            });
        } else if (type == 'qa') {
            qaCheckboxesRef.current.forEach(checkbox => {
                checkbox.checked = checked;
            });
        }
    };

    async function fetchData(useLoader) {
        isFetching = true;
        const res = await UserApiClient.get(`bot/chatbot/sources/${chatbotData.id}/`, {}, true, undefined, useLoader);
        if (res.success) {
            setDocuments(res.data.documents)
            setTexts(res.data.texts)
            setWebsites(res.data.websites)
            setQas(res.data.qas)
            isFetching = false;
        }
    }

    async function hanadleFileSubmit(e) {
        e.preventDefault();
        if (files.length === 0) {
            Swal.fire('Please select at least a file.', '', 'error');
            return;
        }
        const formData = new FormData();
        files.map(file => { formData.append('files', file) })
        await UserApiClient.post(`bot/document/${chatbotData.id}/`, formData, true).then(async res => {
            if (res.success) {
                setFiles([]);
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })
    }

    async function hanadleTextSubmit(e) {
        e.preventDefault();
        if (textHeading == "") {
            Swal.fire('Please add some subject to upload.', '', 'error');
            return;
        }
        if (textArea == "") {
            Swal.fire('Please add some text to upload.', '', 'error');
            return;
        }

        await UserApiClient.post(`bot/text/${chatbotData.id}/`, { text: textArea, text_heading: textHeading }, true).then(async res => {
            if (res.success) {
                setTextArea('');
                setTextHeading('');
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })
    }

    async function hanadleUrlCrawlSubmit(e, multiple) {
        e.preventDefault();
        const url = e.target.url.value

        if (multiple) {
            await UserApiClient.post(`bot/extract/multi-url/`, { domain: url, user_id: User.id, chatbot_id: chatbotData.id }, true).then(async res => {
                if (res.success) {
                    e.target.reset();
                    setUrlFields([...urlFields, ...res.data.links_data]);
                    await fetchData();
                    Swal.fire(`Fetched ${res.data.counts.links_count} links and ${res.data.counts.total_char_count} Characters`, '', '', 3000)
                }
            })
        } else {
            await UserApiClient.post(`bot/extract/single-url/`, { url: url, chatbot_id: chatbotData.id }, true).then(async res => {
                if (res.success) {
                    e.target.reset();
                    setUrlFields([...urlFields, ...res.data.data]);
                    await fetchData();
                    Swal.fire(`Url Fetched successfully.`, '', 'success', 3000)
                }
            })
        }
    }

    async function hanadleSitemapCrawlSubmit(e) {
        e.preventDefault();
        const url = e.target.sitemap_url.value

        await UserApiClient.post(`bot/extract/sitemap-url/`, { url: url, chatbot_id: chatbotData.id }, true).then(async res => {
                if (res.success) {
                    e.target.reset();
                    setUrlFields([...urlFields, ...res.data.links_data]);
                    await fetchData();
                    Swal.fire(`Fetched ${res.data.counts.links_count} links and ${res.data.counts.total_char_count} Characters`, '', '', 3000)
                }
            })
    }

    const addUrlField = async () => {

        let valid = false;
        const data = []
        for (let urldata of urlFields) {
            if (urldata.checked) {
                delete urldata['checked'];
                delete urldata['char'];
                data.push({ ...urldata });
                valid = true;
            }
        }
        if (!valid || urlFields.length == 0 || data.length == 0) {
            Swal.fire('Please add Select at least a URL.', '', 'error');
            return;
        }

        const formData = new FormData();
        formData.append('links', JSON.stringify(data));
        await UserApiClient.post(`bot/website/${chatbotData.id}/`, formData, true).then(async res=>{
            if (res.success) {
                setUrlFields([]);
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })
    };

    async function hanadleQaSubmit(e) {
        e.preventDefault();

        if (inputs.length === 0) {
            Swal.fire('Please add Questions/Answers to upload.', '', 'error');
            return;
        }
        for (let item of inputs) {
            if (item.text_heading === '' || item.question === '' || item.answer === '') {
                Swal.fire('Please fill all fields.', '', 'error');
                return;
            }
        }

        await UserApiClient.post(`bot/qa/${chatbotData.id}/`, inputs, true).then(async res => {
            if (res.success) {
                setInputs([]);
                await fetchData();
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })

    }

    useEffect(() => {
        if (chatbotData.id) {
            if (!isFetching) {
                fetchData();
            }
            const intervalId = setInterval(async()=>{await fetchData(false)}, 10000);
            return () => clearInterval(intervalId);
        }
    }, [chatbotData])


    useEffect(()=>{
        let new_chars = 0
        Documents.map(item=>{new_chars += item.no_of_characters || 0})
        Texts.map(item=>{new_chars += item.no_of_characters || 0})
        Websites.map(item=>{new_chars += item.no_of_characters || 0})
        Qas.map(item=>{new_chars += item.no_of_characters || 0})
        setDetectedChars(new_chars)
    }, [Documents, Texts, Websites, Qas])


    return (
        <>
            <div className='row'>
                <div className='col-sm-12 col-md-2 col-lg-2 col-xl-2 sorcecontainer'>
                    <div className='containers p-0'>
                        <div className='p-2'>
                            <Box>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    className="custom-tabs"
                                    sx={{
                                        borderRight: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-file-import me-3"></i>Files</span></Box>} {...a11yProps(0)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-message me-3"></i>Text</span></Box>} {...a11yProps(1)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-brands fa-chrome me-3"></i>Website</span></Box>} {...a11yProps(2)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center' }}><span><i className="fa-solid fa-comments me-3"></i>Q&A</span></Box>} {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-8 col-lg-8 col-xl-7 sorcecontainers'>
                    <div className='custom-padding'>
                        <TabPanel value={value} index={0} sx={{ padding: '0px' }}>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Files</strong></h4>
                                    {/* <h3 className='mx-4 my-2'>Files</h3> */}
                                </div>
                                <div className="upload">
                                    <FileUploader
                                        multiple={true}
                                        handleChange={handleFileChange}
                                        name="file"
                                        types={fileTypes}
                                    />
                                    <div className='py-2 text-start'>
                                        {files.length > 0
                                            ? files.map(file => <div className='mb-1' style={{borderBottom: '1px solid #cbcbcb', padding: '3px 10px'}}><i class="fa-regular fa-file-lines me-1"></i>{file.name}</div>)
                                            : "No files uploaded yet"}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='text-container px-0'>
                                <h4 className='mx-4 my-2'><strong>Name of Text block</strong></h4>
                                <sapn className='mx-4 my-2'>Dazhboards Files</sapn>
                            </div>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Text Block</strong></h4>
                                </div>
                                <div className="text-block">
                                    <div className="form-group" >
                                        <input className="form-control" onChange={(e) => setTextHeading(e.target.value)} value={textHeading} placeholder='Title' maxLength={255}></input>
                                    </div>
                                    <div className="form-group" >
                                        <textarea className="form-control" rows="10" onChange={(e) => setTextArea(e.target.value)} value={textArea} placeholder='Text' />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Website</strong></h4>
                                </div>
                                <div className="text-block">
                                    <form onSubmit={hanadleUrlCrawlSubmit} className="urlfield mb-3" >
                                        <label for="single_url">Single URL</label><br />
                                        <div className='d-flex'>
                                            <div className='urlfieldinput'>
                                                <input type="url" id="single_url" name="url" placeholder='https://example.com/demo' required />
                                            </div>
                                            <div><button type="submit" className="btn btn-primary ms-2 p-2">Fetch Link</button></div>
                                        </div>
                                    </form>
                                    <small id="Help" className="form-text text-muted">This is crawl the link starting with the URL (not including fields on the website).</small>
                                    <form onSubmit={(e)=>hanadleUrlCrawlSubmit(e, true)} className="urlfield my-3" >
                                        <label for="url">Crawl URLs</label><br />
                                        <div className='d-flex'>
                                            <div className='urlfieldinput'>
                                                <input type="url" id="url" name="url" placeholder='https://example.com/' required />
                                            </div>
                                            <div><button type="submit" className="btn btn-primary ms-2 p-2">Fetch Links</button></div>
                                        </div>
                                    </form>
                                    <small id="Help" className="form-text text-muted">This is crawl all the links starting with the URL (not including fields on the website).</small>
                                    <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>OR</p><hr style={{ width: '40%' }}></hr></div>
                                    <form onSubmit={hanadleSitemapCrawlSubmit} className="urlfield mb-3" >
                                        <label for="sitemap_url">Submit Sitemap</label><br />
                                        <div className='d-flex'>
                                        <div className='urlfieldinput'>
                                        <input type="url" id="sitemap_url" name="sitemap_url" placeholder='https://example.com/sitemap.xml' required /></div>
                                        <button type="submit" className="btn btn-primary ms-2 p-2">Load Additional Sitemap</button>
                                        </div>
                                        <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>Included Links</p><hr style={{ width: '40%' }}></hr></div>
                                    </form>
                                    <div className="urlfield mb-3 overflow-auto" style={{ maxHeight: '560px', scrollbarWidth: 'thin'}} >
                                        {urlFields.length > 0 ? (
                                            <div className='addmore'>
                                                <button type="button" className="btn btn-outline-danger me-2" onClick={() => setUrlFields([])}>Delete all</button>
                                                <button type="submit" className="btn btn-primary p-2" onClick={addUrlField}>Add URLs</button>
                                            </div>
                                        ) : ''}
                                        {urlFields.map((field, index) => (
                                            <div key={index} className='urldatacheck d-flex align-items-center mb-2 pe-3'>
                                                <div>
                                                <input
                                                    className="mx-0"
                                                    type="checkbox"
                                                    onChange={(e) => handleUrlChecked(index, e)}
                                                />
                                                </div>
                                                <input
                                                    type="url"
                                                    value={field.url}
                                                    className='mx-2'
                                                    readOnly
                                                />
                                                <span style={{ padding: "4px", marginRight: "25px" }}>
                                                    {field.char}
                                                </span>
                                                <button type="button" className="icn-btn tableimg p-0" onClick={() => handleDeleteField(field.url)}>
                                                    <img src="/icons/deletebtn.svg" style={{ width: '100%' }} className="" alt="logo" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div className='containers pb-2'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Q&A</strong></h4>
                                </div>
                                <div className='addmore'>
                                    {
                                        inputs == 0 ?
                                            ""
                                            :
                                            <button type="button" className="btn btn-outline-danger me-2" onClick={() => setInputs([])}>Delete all</button>
                                    }
                                    <button type="button" className="btn btn-primary " onClick={addInputField}>+</button>
                                </div>
                                {
                                    inputs == 0 ?
                                        ""
                                        :
                                        <div className="m-1 overflow-auto" style={{ maxHeight: '400px' }}>
                                            {inputs.map((pair, index) => (
                                                <div key={index} className="QuesAns m-2 d-flex align-items-center">
                                                    <div style={{ flex: 1 }}>
                                                        {index !== 0 ? (<hr />) : ''}
                                                        <label className="d-block mb-1">Title</label>
                                                        <input
                                                            type="text"
                                                            name="text_heading"
                                                            className="form-control m-0 h-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Title'
                                                            value={pair.text_heading}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                        <label className="d-block mb-1">Question</label>
                                                        <textarea
                                                            type="text"
                                                            name="question"
                                                            className="form-control m-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Question'
                                                            value={pair.question}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                        <label className="d-block mb-1">Answer</label>
                                                        <textarea
                                                            type="text"
                                                            name="answer"
                                                            className="form-control m-0"
                                                            style={{ borderRadius: "10px" }}
                                                            placeholder='Answer'
                                                            value={pair.answer}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                        onClick={() => deleteInputField(index)}
                                                    >
                                                        <img
                                                            src="/icons/deletebtn.svg"
                                                            style={{ width: '24px', height: '24px' }}
                                                            alt="Delete"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                }
                            </div>
                        </TabPanel>
                    </div>
                </div>
                <div className='col-sm-12 col-md-2 col-lg-2 col-xl-3 sorcecontainerss'>
                    <div className='outer-space'>
                        <div className='containertwo p-3'>
                            <h5><strong>Sources</strong></h5>
                            <div className=''>
                                <span><strong>Total detected character</strong></span><br />
                                <span>{detectedChars}/40000 limit</span>
                            </div>
                            {value !== 2 ? (
                                <button type="button" onClick={value === 0 ? hanadleFileSubmit : value === 1 ? hanadleTextSubmit : value === 3 ? hanadleQaSubmit : ''} className="btn btn-primary" style={{ color: '#fff' }}>Retrain Chatbot</button>
                            ) : ""}
                        </div>
                    </div>
                </div>

                <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>Already included Files</p><hr style={{ width: '40%' }}></hr></div>
                <div className='' style={{ display: 'flex', alignItems: "center" }}>
                    <div className='' style={{ width: '70%' }} >
                        <form className='searchbartab mt-3'>
                            <input type="text" placeholder="Search Source" name="search" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </form>
                    </div>
                    <div className='' style={{ width: '30%', textAlign: "end" }}><button type="button" className="btn btn-outline-danger" onClick={handleMultipleDelete}>Delete Selected</button></div>
                </div>

                {/* Doc/Tex/URL/QA --> Tables */}
                {value === 0 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e)=>{handleMasterCheckboxChange(e, 'file')}}
                                            />
                                            <div className='ms-2'>Document Name</div>
                                        </th>
                                        <th>Chars/Chunks</th>
                                        <th>File Size</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Documents.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    ref={(el) => fileCheckboxesRef.current[index] = el}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.name}
                                            </td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.size ? item.size : 0}KB</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px",
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn" >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='document' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 1 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e)=>{handleMasterCheckboxChange(e, 'text')}}
                                            />
                                            <div className='ms-2'>Heading</div>
                                        </th>
                                        <th>Text</th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Texts.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    ref={(el) => textCheckboxesRef.current[index] = el}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.text_heading?.slice(0, 50)}
                                            </td>
                                            <td>{item.text?.slice(0, 50)}</td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn">
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='text' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 2 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e)=>{handleMasterCheckboxChange(e, 'url')}}
                                            />
                                            <div className='ms-2'>URL</div>
                                        </th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Websites.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    ref={(el) => urlCheckboxesRef.current[index] = el}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.url}
                                            </td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn"  >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='url' onClick={handleDelete}/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : value === 3 ? (
                    <div className='mt-5'>
                        <div className="table-responsive">
                            <table className="table-bordr">
                                <thead>
                                    <tr>
                                        <th className='wide-col'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e)=>{handleMasterCheckboxChange(e, 'qa')}}
                                            />
                                            <div className='ms-2'>Heading</div>
                                        </th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Chars/Chunks</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Qas.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    data-id={item.id}
                                                    ref={(el) => qaCheckboxesRef.current[index] = el}
                                                />
                                                <i className={`fa-regular ${item.status === 'Uploading' ? 'fa-solid fa-globe' : 'fa-file-pdf'} mx-3`}></i>
                                                {item.text_heading?.slice(0, 50)}
                                            </td>
                                            <td>{item.question?.slice(0, 50)}</td>
                                            <td>{item.answer?.slice(0, 50)}</td>
                                            <td>{item.no_of_characters}/{item.no_of_chunks}</td>
                                            <td>{item.created_at}</td>
                                            <td>
                                                <span
                                                    className='text-truncate text-capitalize'
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: item.status == 'success' ? '#47a4e5' : item.status == 'pending' ? '#b9bbbd' : '#ff3346',
                                                        borderRadius: "20px",
                                                        padding: "8px 15px"
                                                    }}
                                                >
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <button type="button" className="icn-btn" >
                                                    <img src="/icons/deletebtn.svg" className="tableimg" alt="logo" data-id={item.id} data-type='qa' onClick={handleDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                ) : ''}
            </div>
        </>
    );
}

export default Sources;