import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Invoice() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([false, false]);

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };


    const navigate = useNavigate();
    const invoice = () => {
        navigate('/user-invoice');
    };

    const handleSelectAllChange = () => {
        const newValue = !selectAll;
        setSelectAll(newValue);
        setSelectedItems(selectedItems.map(() => newValue));
    };

    // Handle change for individual checkboxes
    const handleCheckboxChange = (index) => {
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems[index] = !updatedSelectedItems[index];
        setSelectedItems(updatedSelectedItems);
        setSelectAll(updatedSelectedItems.every(item => item));
    };

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`col-md-2 col-lg-2 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`sizefix ${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>

                        <div className="main-sec mt-5">
                            <div className='row mb-5'>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='invoice-container'>
                                        <p>Monthly Subscription Amount</p>
                                        <h2>$ 1.525,00</h2>
                                        <span>Wednesday, March 16nd 2023, 12:14 AM</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='invoice-containers'>
                                        <div className='invoice-blue'>
                                            <div className='icn-box'><i className="fa-regular fa-bookmark fa-xl"></i></div>
                                            <div>
                                                <p>Total Users</p>
                                                <h2>24</h2>
                                            </div>
                                        </div>
                                        <div className='invoice-green'>
                                            <div className='icn-box'><i className="fa-regular fa-paper-plane fa-xl"></i></div>
                                            <div>
                                                <p>Paid Invoices</p>
                                                <h2>24</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='invoice-containers'>
                                        <div className='invoice-purple'>
                                            <div className='icn-box'><i className="fa-solid fa-angles-right fa-xl"></i></div>
                                            <div>
                                                <p>Invoice</p>
                                                <h2>24</h2>
                                            </div>
                                        </div>
                                        <div className='invoice-red'>
                                            <div className='icn-box'><i className="fa-regular fa-circle-xmark fa-xl"></i></div>
                                            <div>
                                                <p>Unpaid Invoices</p>
                                                <h2>24</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='' style={{ display: 'flex', alignItems: "center" }}>
                                <div className='col-sm-4 col-md-5 col-lg-6 col-xl-6'  >
                                    <form className='searchbartab my-3'>
                                        <input type="text" placeholder="Search Invoice" name="search" />
                                        <button type="submit"><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                                <div className='col-sm-8 col-md-7 col-lg-6 col-xl-6 selectbtns'>
                                    <button className='btn btn-primary px-4 py-2'>Download Selected</button>
                                    <button onClick={invoice} className='btn btn-primary px-4 py-2 mx-4'>Create Invoice</button>
                                    <button type="button" className="btn btn-outline-danger">Delete Selected</button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="table-responsive">
                                        <table className="table category-tab">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" style={{ paddingLeft: "30px" }}>
                                                        <input
                                                            className="form-check-input"
                                                            style={{ position: 'unset' }}
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                    </th>
                                                    <th scope="col">Invoice</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Plan</th>
                                                    <th scope="col">Cost</th>
                                                    <th scope="col">Download</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedItems.map((isChecked, index) => (
                                                    <tr key={index}>
                                                        <th scope="col" style={{ paddingLeft: "30px" }}>
                                                            <input
                                                                className="form-check-input"
                                                                style={{ position: 'unset' }}
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />
                                                        </th>
                                                        <td>#NB00{index + 1}</td>
                                                        <td>user@email.com</td>
                                                        <td>02/24</td>
                                                        <td>
                                                            <span style={{ backgroundColor: index === 0 ? '#fdf3b9' : '#efdac5', borderRadius: '20px', padding: '5px 20px', border: index === 0 ? '1px solid #fcd736' : '1px solid brown' }}>
                                                                {index === 0 ? 'Gold' : 'Bronze'}
                                                            </span>
                                                        </td>
                                                        <td>224</td>
                                                        <td>
                                                            <span style={{ backgroundColor: '#4ea4e2', borderRadius: '20px', padding: '5px 20px', color: '#fff' }}>
                                                                <i className="fa-regular fa-file"></i>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                            <i className="fa-solid fa-pen-to-square mx-4"></i>
                                                            <i className="fa-solid fa-trash-can mx-2"></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Invoice;
