import Swal from 'sweetalert2';
import '../../Admin/style.css';
import React, { useContext } from 'react';
import { UserContext } from '../../TabContextProvider';
import { useNavigate } from 'react-router-dom';

function Adminheader({ toggleNavbar, isNavbarVisible }) {

  const {User, setUser} = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = function (e) {
    setUser({})
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/');
    Swal.fire({
      title: "Logout Successfull.",
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })
  };

  return (
    <>
      <div className="header-sec mb-0">
      <button 
          className="btn toggle-btn headtoggle" 
          onClick={toggleNavbar}
        >
          {/* {isNavbarVisible ? 'Hide Navbar' : 'Show Navbar'} */}
          <i className="fa-solid fa-bars "></i>
        </button>
      
        <div className='headerlogo-sec'>
          <img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: '80%' }} />
        </div>
        <div className="input-group">
          <div className='Adheadname'>
            <h4>Admin Dashboard</h4>
          </div>
          <form className='searchbar'>
            <input type="text" placeholder="Search.." name="search" />
            <button type="submit"><i className="fa fa-search"></i></button>
          </form>
        </div>

        <div className='mobdrop'>
          <div className="dropdown" style={{ display: "flex" }}>
            <img src='/assets/profile-user.png' alt="user-logo" style={{ width: "40px" }}></img>
            <button className="btn dropdown-toggle pe-4" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {User?.email}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a className="dropdown-item" href="/">Action</a></li>
              <li><a className="dropdown-item" href="/">Another action</a></li>
              <li><div className="dropdown-item" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>

        <div className='deskdrop'>
          <div className="dropdown" style={{ display: "flex" }}>
            <img src='/assets/profile-user.png' alt="user-logo" style={{ width: "40px" }}></img>
            <button className="btn dropdown-toggle pe-4" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              {User?.email}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a className="dropdown-item" href="/">Dashboard</a></li>
              <li><a className="dropdown-item" href="/">Chatbot</a></li>
              <li><a className="dropdown-item" href="/">Resources</a></li>
              <li><a className="dropdown-item" href="/">Help</a></li>
              <li><a className="dropdown-item" href="/">Account Setting</a></li>
              <li><div className="dropdown-item" onClick={handleLogout}>Log out</div></li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
}

export default Adminheader;
