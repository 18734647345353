import "../App.css";
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Account from './Customs/setting/Account.jsx';
import Billing from './Customs/setting/Billing.jsx';
import Security from './Customs/setting/Security.jsx';
import Teams from './Customs/setting/Teams.jsx';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MainSetting() {
    const [value, setValue] = useState(0);
    const [isMobCheck, setIsMobCheck] = useState(window.innerWidth >= 768);

    const handleResize = () => {
        setIsMobCheck(window.innerWidth >= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="main-sec mt-5">
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 mainswitch'>
                        <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                <Tab label="Account" {...a11yProps(0)} />
                                <Tab label="Security" {...a11yProps(1)} />
                                <Tab label="Billing / Plans" {...a11yProps(2)} />
                                <Tab label="Teams" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                    </div>
                    <div className='col-6'>
                    </div>
                </div>
                <CustomTabPanel value={value} index={0}>
                    <Account/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Security />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Billing />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Teams />
                </CustomTabPanel>
            </div>
        </>
    );
}

export default MainSetting;
