import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import UserApiClient from "../ApiClients";
import Swal from "sweetalert2";

const GoogleCallBack = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const error = query.get('error');
    if (error) {
        Swal.fire(error, '', 'error', 3000);
    }
    const email = query.get('email');
    const token = query.get('token');
    const userId = query.get('userId');

      // Your API call to fetch user data
    const fetchData = async () => {
        await UserApiClient.get(`api/user/${userId}/`, {}, true).then(async res=>{
            if (!res.success) {
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                window.location.href = '/'
            } else {
                window.location.href = '/'
            }
        })
    }

    useEffect(()=>{
        if (!error) {
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            fetchData();
        }
    }, [error, email, token, userId])

    return(
        <>
            {!error?(
                <>
                    <h1>Please wait...</h1>
                    {email && <p>User: {email}</p>}
                </>
            ):(
                <>
                    {error && <p>Error: {error}</p>}
                </>
            )}

        </>
    )
  }
  
  export default GoogleCallBack