import "../../style.css"
import React from 'react';


function Account() {

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <form>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="firstName">First Name</label>
                                    <input type="text" id="firstName" className="form-control" placeholder="First name" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="lastName">Last Name</label>
                                    <input type="text" id="lastName" className="form-control" placeholder="Last name" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="email">Your E-mail</label>
                                    <input type="email" id="email" className="form-control" placeholder="Your E-mail" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="phoneNumber">Your Phone Number</label>
                                    <input type="tel" id="phoneNumber" className="form-control" placeholder="Your Phone Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="address">Your Address</label>
                                    <input type="text" id="address" className="form-control" placeholder="Your Address" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="state">Select Your State</label>
                                    <input type="text" id="state" className="form-control" placeholder="Select your state" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="zipCode">Zip Code</label>
                                    <input type="text" id="zipCode" className="form-control" placeholder="Zip code" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6ol">
                                    <label for="country">Select Your Country</label>
                                    <input type="text" id="country" className="form-control" placeholder="Select Your Country" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="language">Language</label>
                                    <input type="text" id="language" className="form-control" placeholder="Language" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label for="timeZone">Time Zone</label>
                                    <input type="text" id="timeZone" className="form-control" placeholder="Time zone" />
                                </div>
                            </div>
                        </form>
                        <div className="row mt-5">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <button type="button" className="btn btn-primary" style={{ width: "100%" }}>Save Changes</button>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <button type="button" className="btn btn-secondary" style={{ width: "100%" }}>Reset</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </>
    );
}

export default Account;
