import '../App.css';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';

function Forget() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({})

  const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
  const handleFormSubmit = async (e) => {
      e.preventDefault();

      await UserApiClient.post('api/password-reset/', formData).then( async res =>{
          let resp = await res.success;
          let data = await res.data
          if (resp && data) {
            e.target.reset();
            Swal.fire({
              title: res.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
            navigate("/")
          } else {
              console.log(data.detail)
          }
      })
  };
  
  return (
    <>
      <div className="row user-login">
        <div className='col-sm-0 col-md-3 col-lg-5 col-xl-6 logo-set'>
        <div className='backarrow'><i class="fa-solid fa-circle-arrow-left my-4 mx-5" onClick={()=>navigate('/')} style={{ fontSize: '24px', color: "#47a4e5", cursor: "pointer"}}></i></div>
          <div className='logo-img'><img src="/assets/Layer_1.png" className="logo" onClick={()=>navigate('/')} alt="logo" style={{width: "50%", cursor: "pointer"}}/></div>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-7 col-xl-6 login-form'>
          <div className='forgetform'>
          <div className='signin'>
            <h4>Forget Password?</h4>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                 <small id="emailHelp" className="form-text text-muted">No worries! Just enter you email and we'll send you  a reset password link.</small>
                <input type="email" className="form-control" placeholder="Email*" name='email' onChange={handleChange} required/>
              </div>
              
              <button type="submit" className="btn btn-primary signin-btn" >Send Recovery Email</button>
              <div className='mt-4' style={{ textAlign: 'center' }}><h6>Just Remember? <NavLink to='/login'> Sign In</NavLink></h6></div>
            </form>
          </div>
         
          </div>
        </div>
      </div>
    </>
  );
}

export default Forget;
