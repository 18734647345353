import React from 'react';
import { GoogleRedirectUrl, GoogleClientId } from '../Configs/enviroment';


const onGoogleLoginSuccess = () => {
  const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

  const scope = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ].join(' ');

  const params = {
    response_type: 'code',
    client_id: GoogleClientId,
    redirect_uri: GoogleRedirectUrl,
    prompt: 'select_account',
    access_type: 'offline',
    scope
  };

  const urlParams = new URLSearchParams(params).toString();
  window.location.href = `${GOOGLE_AUTH_URL}?${urlParams}`

};


const GoogleSignIn = () => {
  return(
    <>
      <button type="button" onClick={onGoogleLoginSuccess} className="google-signin mb-3"><i className="fa-brands fa-google"></i> Google</button>
    </>
  )
  // return <GoogleButton onClick={onGoogleLoginSuccess} type="light" label="Sign in with Google"/>
}

export default GoogleSignIn
