import React from 'react';
import '../App.css';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function Navbar({ chatbotList }) {

    const navigate = useNavigate();
    // const UserChatbots = () => {
    //     navigate('/chat-bots');
    //   };

    return (
        <>
            <div className='navmain'>
                <ul>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
                    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet"></link>
                    <li>
                        <div className='navitems'><i className="fa fa-home" aria-hidden="true"></i>
                            <NavLink className="nav-link px-4 py-3" to="/chat-bots">Dashboard</NavLink>
                        </div>
                    </li>
                </ul>
                <div className="mx-4 my-4" style={{ color: "gray" }}>
                    <h6>Chatbot Settings</h6>
                </div>

                <ul>
                    {/* <li>
                    <div className='navitems'>
                    <i className="fa-solid fa-robot"></i>
                    <i className="fa-regular fa-hard-drive"></i>
                        <NavLink className="nav-link px-4 py-2" to="/layout?tab=0">Chatbots</NavLink>
                    </div>
                </li> */}
                    <li className="nav-li mt-2">
                        <i className="fa-solid fa-robot"></i>
                        {/* <a className="text-decoration-none ps-4 pe-2 py-2 chatpointer" data-bs-toggle="collapse" aria-expanded="false" onClick={UserChatbots} style={{ color: "#4a545d" }}>
                        <span className="menu-label">Chatbots</span>
                    </a> */}
                        <a className="dropdown-toggle text-decoration-none  ps-4 py-2" href="#settingMenuone" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                            <span className="menu-label">Chatbots</span>
                        </a>
                        <ul className="collapse list-unstyled ms-5 chatuserlist" id="settingMenuone">
                            {chatbotList && chatbotList.map((item, index) => (
                                <li key={index} className='my-2 ms-2 text-capitalize'>
                                    <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={`/layout/${item.id}?tab=0`}>
                                    <img src="http://127.0.0.1:8000//media/default_image/1.png" style={{width: "18px" , marginRight:"5px"}}/>{item.chatbot_name}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>

                <div className="mx-4 my-3" style={{ color: "gray" }}>
                    <h6>Others</h6>
                </div>

                <ul>
                    {/* <li>
                    <div className='navitems'>
                    <i className="fa-regular fa-hard-drive"></i>
                        <NavLink className="nav-link px-4 py-2" to="/layout?tab=1">Resources</NavLink>
                    </div>
                </li> */}

                    <li>
                        <div className='navitems'>
                            <i className="fa-solid fa-wallet"></i>
                            <NavLink className="nav-link px-4 py-2" to="/help">Help</NavLink>
                        </div>
                    </li>
                {/* </ul> */}

                {/* <ul>
                   
                    <li className="nav-li mt-2">
                        <i className="fa-solid fa-robot"></i>
                        <a className="dropdown-toggle text-decoration-none  ps-4 py-2" href="#settingMenuone" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                            <span className="menu-label">Chatbots</span>
                        </a>
                        <ul className="collapse list-unstyled ms-5 chatuserlist" id="settingMenuone">
                            <li className='my-2 ms-2 text-capitalize'>
                                <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={'/settings'}>Setting</NavLink><br/>
                                <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={'/settings'}>Account Setting</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul> */}

                {/* <ul> */}
                    <li>
                        <div className='navitems'>
                            <i className="fa-solid fa-gear"></i>
                            <NavLink className="nav-link px-4 py-2" to="/settings">Account Setting</NavLink>
                        </div>
                    </li>
                    {/* <li>
                    <div className='navitems'>
                        <i className="fa-solid fa-right-from-bracket"></i>
                        <NavLink className="nav-link px-4 py-2" to="/">Sign Out</NavLink>
                    </div>
                </li> */}

                </ul>
                <div className='upgradenow'>
                    <h6 style={{ lineHeight: "inherit" }}>Get X$ Amount off if you Subscribe Yearly!</h6>
                    <button type="button" className="btn btn-light">Upgrade Now!</button>
                </div>
            </div>
            <div className='adcopyright'>
                <h6>NimiBot</h6>
                <small className="form-text text-muted"><i className="fa-regular fa-copyright"></i>2024 All Rights Reserved</small>
                <small className="form-text text-muted">Made with <i className="fa-solid fa-heart" style={{ color: "#ff0008" }}></i> by NimiBot</small>
            </div>
        </>
    );
}

export default Navbar;