import '../../../App.css';
import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from '../../../TabContextProvider';
import UserApiClient from '../../../ApiClients';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Account() {
    
    const navigate = useNavigate();
    const {User, setUser} = useContext(UserContext);

    const [imageSrc, setImageSrc] = useState('/assets/useravatar.png');
    const [userData, setUserData] = useState({...User});
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);

            setUserData((prevState) => ({
                ...prevState,
                profile_pic: file,
            }));
            setSelectedFile(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        for (const key in userData) {
            if (key === 'profile_pic' && !selectedFile) {
                // Skip appending profile_pic if no file is selected
                continue;
            }
            formData.append(key, userData[key]);
        }

        await UserApiClient.put(`api/user/update/${User.id}/`, formData, true).then(async res => {
            if (res.success) {
                setUser({...userData, ...res.data.data});
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })   
    }

    async function handleDeactiveAccount(e) {
        e.preventDefault();
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await UserApiClient.put(`api/user/status/${User.id}/`, {is_active: false}, true);
                if (res.success) {
                    localStorage.removeItem('token');
                    setUser({})
                    navigate('/login');
                    Swal.fire('Account deactivated.', '', 'success', 3000)
                }
            }
        });
    }    

    useEffect(()=>{
        if (User?.profile_pic) {
            setImageSrc(User.profile_pic)
        }
        setUserData({...User})
    }, [User])

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='useraccount-setting pt-0'>
                        <form onSubmit={handleFormSubmit}>
                            <div className='userimgup'>
                                <img
                                    src={imageSrc}
                                    style={{ width: '10%' }}
                                    className=""
                                    alt="User Avatar"
                                    onClick={handleImageClick}
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept='image/*'
                                    name='profile_pic'
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.first_name} maxLength={255} onChange={handleChange} name="first_name" id="firstName" className="form-control" placeholder="First name" required />
                                        <label htmlFor="firstName">First Name</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.last_name} maxLength={255} onChange={handleChange} name="last_name" id="lastName" className="form-control" placeholder="Last name" required />
                                        <label htmlFor="lastName">Last Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="email" defaultValue={userData.email} onChange={handleChange} id="email" className="form-control" placeholder="Your E-mail" readOnly disabled/>
                                        <label htmlFor="email">Your E-mail</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" defaultValue={userData.phone_number} max={9999999999} maxLength={10} min={1000000000} onChange={handleChange} name="phone_number" id="phoneNumber" className="form-control" placeholder="Your Phone Number" required />
                                        <label htmlFor="phoneNumber">Your Phone Number</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.address} onChange={handleChange} name="address" id="address" className="form-control" placeholder="Your Address" required />
                                        <label htmlFor="address">Your Address</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.state} maxLength={255} onChange={handleChange} name="state" id="state" className="form-control" placeholder="Select your state" required />
                                        <label htmlFor="state">Select Your State</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.zip_code} maxLength={6} onChange={handleChange} name="zip_code" id="zipCode" className="form-control" placeholder="Zip code" required />
                                        <label htmlFor="zipCode">Zip Code</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.country} maxLength={255} onChange={handleChange} name="country" id="country" className="form-control" placeholder="Select Your Country" required />
                                        <label htmlFor="country">Select Your Country</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.language} maxLength={255} onChange={handleChange} name="language" id="language" className="form-control" placeholder="Language" required />
                                        <label htmlFor="language">Language</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" defaultValue={userData.timezone} maxLength={255} onChange={handleChange} name="timezone" id="timeZone" className="form-control" placeholder="Time zone" required />
                                        <label htmlFor="timeZone">Time Zone</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <form onSubmit={handleDeactiveAccount}>
                        <div className='billingContainer mt-3'>
                            <h5><strong>Delete Account</strong></h5>
                            <div className='dltcontainer'>
                                <p><strong>Are you sure you want to delete your account?</strong></p>
                                <p>Once you delete your account, there is no going back. Please be certain.</p>
                            </div>
                            <div className="form-check my-4" >
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    I confirm my account deactivation
                                </label>
                            </div>
                            <button type="submit" className="btn btn-danger">Deactivate account</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default Account;
