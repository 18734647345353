import '../../../Admin/style.css';
import React from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Adduser() {

    const navigate = useNavigate();
    const backtouser = () => {
        navigate('/all-user');
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec mt-5">
                            <div className='serchblog p-4' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "100%" }}>Add User</h5>
                                </div>
                                <button onClick={backtouser} className='btn btn-primary px-4 py-2'>Back to user List</button>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <form>
                                        <div className="form-group">
                                            <label for="exampleInputtext">User Name</label>
                                            <input type="text" className="form-control py-4 mt-0" id="exampleInputtext" aria-describedby="emailHelp" placeholder="Full Name" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">User Email</label>
                                            <input type="email" className="form-control py-4 mt-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputnum">Mobile Number</label>
                                            <input type="number" className="form-control py-4 mt-0" id="exampleInputnum" aria-describedby="emailHelp" placeholder="Mobile Number" />
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary signin-btn py-3 mt-5" onClick={''}>Add  User</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Adduser;
