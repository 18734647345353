import '../../../App.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Teams() {

    const [users, setUsers] = useState([

        { id: 'webtunix.developers@gmail.com', username: 'May 16, 2024', name: 'Admin' },
        { id: 'webtunix.developers@gmail.com', username: 'May 16, 2024', name: 'Member' }
    ]);
    const [appearanceData, setAppearanceData] = useState();


    const handleAppearanceChange = (e) => {
        const { name, value } = e.target;
        setAppearanceData({
            [name]: value,
        });
    };

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <div className='billingContainer'>
                            <div className='mt-4'>
                                <h4><strong>General</strong></h4>
                                <div className="form-group interface">
                                    <label htmlFor="display_name">Team Name</label>
                                    <input type="text" className="form-control"
                                        name="display_name"
                                        id="display_name"
                                        onChange={handleAppearanceChange}
                                        defaultValue={''}
                                        minLength={1}
                                        maxLength={100}
                                        placeholder="Webtunix Developers's team" />
                                </div>

                                <div className="form-group interface">
                                    <label htmlFor="display_name">Team URL</label>
                                    <input type="email" className="form-control"
                                        name="display_name"
                                        id="display_name"
                                        onChange={handleAppearanceChange}
                                        defaultValue={''}
                                        minLength={1}
                                        maxLength={100}
                                        placeholder="webtunix-developers-team-2b549a7e" />
                                    <small className="form-text text-muted">Updating Team URL will cause a redirect to the new url.</small>
                                </div>
                                <div className='d-flex justify-content-end'> <button type="submit" className="btn btn-primary px-5">Save</button></div>
                            
                        <div className='billingtable mt-4'>
                            <label className='my-2' htmlFor="firstName">Members</label>
                            <div className='col-12 p-0'>
                                <div className="table-responsive">
                                    <table className="table category-tab text-start">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">User</th>
                                                <th scope="col">Member since</th>
                                                <th scope="col">Role</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map(user => (
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td className='text-capitalize'>
                                                        <Link to={`/user-profile/${user.id}`}>{user.username} {user.last_name}</Link>
                                                    </td>
                                                    <td>{user.name}</td>
                                                    <td>
                                                        <button className='icn-btn'>
                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-flex justify-content-end'> <button type="submit" className="btn btn-primary">Invite members</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                        </div>




                <form onSubmit={''}>
                    <div className='billingContainer mt-3'>
                        <h5><strong>Delete Team</strong></h5>
                        <div className=''>
                            <p>Once you delete your team account, there is no going back. Please be certain.<br /> All your uploaded data and trained chatbots will be deleted. This action is not reversible</p>
                        </div>
                        <div className="form-check my-4" >
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                I confirm my Team deactivation
                            </label>
                        </div>
                        <div className='d-flex justify-content-end'> <button type="submit" className="btn btn-danger px-4">Delete Team</button></div>
                    </div>
                </form>
            </div>

        </>
    );
}

export default Teams;
