import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import OpenHeader from '../Components/OpenHeader';

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }


    return (
        <>
            {/* Header */}
            <OpenHeader />
            {/* Header */}
            <div className="contact-us">
                <div className='dash-contact' style={{background:" #fff"}}>
                    <h1 style={{color:" #a1a1aa"}} >Contact Us</h1>
                    <p>NimiBot provides a simple and secure way to create custom AI Chatbot.</p>
                    <form>
                        <div className="row">
                            <div className="col">
                                <input type="text" className="form-control" placeholder="Full Name" />
                            </div>
                            <div className="col">
                                <input type="email" className="form-control" placeholder="Your email" />
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" placeholder="Message" rows="3" id="comment"></textarea>
                        </div>
                    </form>
                    <button type="button" className="btn btn-primary px-5 mb-3">Send</button>
                    <p>contact@nimibot.com</p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;