import '../App.css';
import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';

function OpenHeader() {
    const { User } = useContext(UserContext);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const navigate = useNavigate();
    const logPage = () => {
        navigate('/login');
    }
    const SignPage = () => {
        navigate('/signup');
    }
    const layoutPage = () => {
        if (User?.is_superuser) {
            navigate('/all-user');
        } else if (!User?.is_superuser) {
            navigate('/chat-bots');
        }
    }



    return (

        <>
            {/* Header */}
            <div className="row maindash-nav">
                <div className='col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                    <div className=''>
                        <img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: '80%' }} />
                    </div>
                </div>
                <div className='col-sm-0 col-md-6 col-lg-6 col-xl-8'>
                    <div className='dash-nav'>
                        <ul>
                            <li>
                                <NavLink className="nav-link " to="/">Home</NavLink>
                            </li>
                            <li>
                                <a href='#features' className="nav-link ">Features </a>
                            </li>
                            <li>
                                <a href='#price' className="nav-link ">Price </a>
                            </li>
                            <li>
                                <NavLink to='/Contact-Us' className="nav-link ">Contact </NavLink>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className='col-sm-6 col-md-2 col-lg-3 col-xl-2 text-center'>
                    <div className='dashlogin'>
                        {Object.keys(User).length == 0 ? (
                            <>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Login</button>
                                <button type="button" onClick={SignPage} className="btn btn-primary">Sign Up</button>
                            </>
                        ) : (
                            <button type="button" onClick={layoutPage} className="btn btn-primary">Dashboard</button>
                        )}
                    </div>
                </div>
            </div>
            <div className='dash-mob'>
                <div>
                    {Object.keys(User).length == 0 ? (
                        <>
                            <img src="/assets/logo4.png" className="logo dash-logo" alt="logo" />
                            <div>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Login</button>
                                <button type="button" onClick={layoutPage} className="btn btn-primary">Sign Up</button>
                            </div>
                        </>
                    ) : (
                        <button type="button" onClick={SignPage} className="btn btn-primary">Dashboard</button>
                    )}
                    {/* <img src="/assets/Layer_1.png" className="logo dash-logo" alt="logo" />
                    <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Login</button>
                    <button type="button" onClick={SignPage} className="btn btn-primary">Sign Up</button> */}
                </div>
            </div>
            {/* Header */}
        </>
    );
}

export default OpenHeader;