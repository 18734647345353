import { NavLink, useNavigate } from 'react-router-dom';
import '../App.css';
import React, { useState } from 'react';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';
import GoogleSignIn from './LoginWithGoogle';

function SignUp() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        await UserApiClient.post('api/register/', formData).then( async res =>{
            let resp = await res.success;
            let data = await res.data
            if (resp && data) {
                Swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                  })
                navigate('/login');
            } else {
                console.log(data.detail)
            }
        })
    };

    return (
        <>
            <div className="row user-login">
                <div className='col-sm-0 col-md-3 col-lg-5 col-xl-6 logo-set'>
                <div className='backarrow'><i class="fa-solid fa-circle-arrow-left my-4 mx-5" onClick={()=>navigate('/')} style={{ fontSize: '24px', color: "#47a4e5", cursor: "pointer"}}></i></div>
                    <div className='logo-img'><img src="/assets/Layer_1.png" className="logo" onClick={()=>navigate('/')} alt="logo" style={{width: "50%", cursor: "pointer"}} /></div>
                </div>
                <div className='col-sm-12 col-md-9 col-lg-7 col-xl-6 login-form'>
                    <div className='signin-set'>
                        <div className='signin'>
                            <h3>Sign Up</h3>
                            <form onSubmit={handleFormSubmit}>
                                <div className="form-group">
                                    <input type="email" name='email' className="form-control" onChange={handleChange} placeholder="Enter email" required/>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" name='first_name' onChange={handleChange} className="form-control m-0" placeholder="First name" required/>
                                    </div>
                                    <div className="col">
                                        <input type="text" name='last_name' onChange={handleChange} className="form-control m-0" placeholder="Last name" required/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="password" name='password' className="form-control" onChange={handleChange} placeholder="Password" required/>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id='termOfUseCheckbox' required/>
                                    <label className="form-check-label" htmlFor="termOfUseCheckbox">
                                    By Clicking Create account, I agree that I have read and accepted the <NavLink href='/privacy-policy'>Terms of use</NavLink> and <NavLink href='/terms-of-service'>Privacy Policy</NavLink>
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary signin-btn mt-4">Sign Up</button>
                                <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>or</p><hr style={{ width: '40%' }}></hr></div>
                                <div className=''>
                                    <GoogleSignIn />
                                    <small id="emailHelp" className="form-text text-muted">Protected by reCAPTCHA and Subject to the Nimibot <NavLink to='/privacy-policy'>Privacy Policy</NavLink> and <NavLink to='/terms-of-service'>Terms of service</NavLink></small>
                                </div>
                            </form>
                        </div>
                        <div className='mt-4' style={{ textAlign: 'center' }}><h6>Already have an account? <NavLink to='/login'>Sign In</NavLink></h6></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;
