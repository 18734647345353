import '../../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../TabContextProvider';

function Billing() {
    const {User} = useContext(UserContext);
    const [plan, setPlan] = useState({});
    const [planConfig, setPlanConfig] = useState({});
    const [users, setUsers] = useState([

        { id: 1, username: 'Mark', name: 'Otto', generation: '1234', email: 'mark@example.com', phone: '1234567890', tier: '12-02-2024', status: true, trialPeriod: '48 Hours' },
        { id: 2, username: 'Jacob', name: 'Thornton', generation: '4567', email: 'jacob@example.com', phone: '0987654321', tier: '12-01-2024', status: false, trialPeriod: '24 Hours' }
    ]);
    const [appearanceData, setAppearanceData] = useState();


    const handleAppearanceChange = (e) => {
        const { name, value } = e.target;
        setAppearanceData({
            [name]: value,
        });
    };

    useEffect(()=>{
        if (User.plan) {
            setPlan(User.plan);
            if (User.plan.subscription_config) {
                setPlanConfig(User.plan.subscription_config);
            }
        }
    }, [User])
    
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <div className='billingContainer'>
                            <h4><strong>Current Plan</strong></h4>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='mt-3' for="firstName">Subscription Details</label>
                                    <p className='mb-3' for="firstName">You are on the
                                        <span style={{ fontSize: "12px", margin: '0px 5px', backgroundColor: '#47a4e58c', borderRadius: '20px', padding: '2px 10px', border: '1px solid #4c8afa' }} >{planConfig.plan}</span>
                                        plan {planConfig.subscription_type=='free'?(<strong>free forever</strong>):''}</p>
                                    <div className='p-4'>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> {plan.messages} message credits/month</p>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> {plan.chatbots} chatbot</p>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> {plan.embeddings} characters/chatbot</p>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> Limit to {plan.links} links to train on</p>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> Embed on {plan.embedded} websites</p>
                                        <p className='mb-2'><i class="fa-solid fa-circle-check green-icon"></i> View chat history</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <label className='my-3' for="firstName">Usage</label>
                                    <div className='p-4'>
                                        <p>Credits consumed: <strong>{'0'}</strong></p>
                                        <p>Subscription limit: <strong>20</strong></p>
                                        <p>Your credits renew at the start of every calendar month but your extra credits will remain as it is.</p>
                                        <p>Next renewal: <strong>{plan.expiry_date}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                            <h4><strong>Billing Details</strong></h4>
                                <div className="form-group interface">
                                    <label htmlFor="display_name">Organisation Name</label>
                                    <input type="text" className="form-control"
                                        name="display_name"
                                        id="display_name"
                                        onChange={handleAppearanceChange}
                                        defaultValue={''}
                                        minLength={1}
                                        maxLength={100}
                                        placeholder="Organisation" />
                                </div>
                                <div className="form-group interface">
                                    <label htmlFor="exampleInputtext">Country or region</label>
                                    <select className="form-select" value={appearanceData?.widget_width} name='widget_width' onChange={handleAppearanceChange}>
                                        <option value="400">India</option>
                                        <option value="450">UK</option>
                                        <option value="500">US</option>
                                    </select>
                                </div>
                                <div className="form-group interface">
                                    <label htmlFor="display_name">Address line 1</label>
                                    <input type="text" className="form-control"
                                        name="display_name"
                                        id="display_name"
                                        onChange={handleAppearanceChange}
                                        defaultValue={''}
                                        minLength={1}
                                        maxLength={100}
                                        placeholder="Street Address" />
                                </div>

                                <div className="form-group interface">
                                    <label htmlFor="display_name">Billing Email</label>
                                    <small className="form-text text-muted">By default, all invoices will be sent to the email address of the team's creator. If you prefer to use a different email address for receiving invoices, please enter it here.</small>
                                    <input type="email" className="form-control"
                                        name="display_name"
                                        id="display_name"
                                        onChange={handleAppearanceChange}
                                        defaultValue={''}
                                        minLength={1}
                                        maxLength={100}
                                        placeholder="webtunix.developers@gmail.com" />
                                </div>
                            </div>
                            <hr />
                            <div className="row mt-5">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="button" className="btn btn-primary" style={{ width: "100%" }}>Upgrade Plan</button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="button" className="btn btn-secondary" style={{ width: "100%" }}>Manage Subscription</button>
                                </div>
                            </div>
                        </div>
                        <div className='billingtable mt-4'>
                            <label className='my-2' for="firstName">Billing History</label>
                            <div className='col-12 p-0'>
                                <div className="table-responsive">
                                    <table className="table category-tab text-start">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Sr.No.</th>
                                                <th scope="col">Invoice ID</th>
                                                <th scope="col">Subscription Type</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map(user => (
                                                <tr key={user.id}>
                                                    <td>#{user.id}</td>
                                                    <td className='text-capitalize' onClick={() => window.location.href = `/user-profile/${user.id}`}>{user.username} {user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.date_joined}</td>
                                                    <td>
                                                        <span style={{
                                                            backgroundColor: user.generation === 'Gold' ? '#fdf3b9' : '#efdac5',
                                                            borderRadius: '20px',
                                                            padding: '5px 20px',
                                                            border: `1px solid ${user.generation === 'Gold' ? '#fcd736' : 'brown'}`
                                                        }}>
                                                            {user.tier}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {/* <label className="switch">
                                                            <input type="checkbox" defaultChecked={user.is_active} /> */}
                                                        {/* <input type="checkbox" defaultChecked={user.is_active} onChange={(e)=>handleUserStatusChange(e, user.id)} /> */}
                                                        {/* <span className="slider round"></span>
                                                        </label> */}
                                                    </td>
                                                    <td>
                                                        <button className='icn-btn'>
                                                            {/* <button className='icn-btn' onClick={() => handleDelete(user.id)}> */}
                                                            <i className="fa-solid fa-trash-can mx-2"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </>
    );
}

export default Billing;
