import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import UserApiClient from '../../../ApiClients/index.jsx';

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Userprofiles() {

    const {id} = useParams();
    const navigate = useNavigate();
    let isFetching = false;

    const [userData, setUserData] = useState({});
    const [value, setValue] = useState(0);
    const location = useLocation();
    const [invoices, setInvoices] = useState([]);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    // Your API call to fetch data
    const fetchData = async () => {
        isFetching = true
        try {
            const res = await UserApiClient.get(`api/user/${id}/`, {}, true);
            if (res.success) {
                setUserData(res.data);
            }
            isFetching = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };
    
    useEffect(() => {
        if (id && !isFetching) {
            fetchData()
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIndex = parseInt(params.get('tab'), 10);
        if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 2) {
            setValue(tabIndex);
        }
    }, [location.search]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Update URL to reflect the current tab
        const params = new URLSearchParams(window.location.search);
        params.set('tab', newValue);
        window.history.replaceState(null, '', `?${params.toString()}`);
    };
    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    return (
        <>
            <div className="container-fluid p-0">
            <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible}/>
                <div className="row">
                    <div className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                     style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>
                        <div className="main-sec">
                            <div className='serchblog p-4' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "100%" }}>User Profile</h5>
                                </div>
                                <button onClick={() => {navigate('/all-user')}} className='btn btn-primary px-4 py-2'>Users List</button>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='userprofile'>
                                        {/* <div className='bloglist' style={{justifyContent:'end'}}>
                                        <button><span>Edit<i className="fa-solid fa-pen-to-square ml-2"></i></span></button>
                                    </div> */}
                                        <div style={{ display: 'flex' }}>
                                            <img src='/assets/profile-user.png' alt="user-logo" style={{ width: "10%", marginRight: "25px" }}></img>
                                            <div>
                                                <h1 className='text-capitalize'>{userData?.first_name} {userData?.last_name}</h1>
                                                <p>{userData?.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-12 col-lg-10 col-xl-5 mainswitch'>
                                            <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="About" {...a11yProps(0)} />
                                                    <Tab label="Plan/Usage" {...a11yProps(1)} />
                                                    <Tab label="Billing" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                        </div>
                                    </div>
                                    <CustomTabPanel value={value} index={0}>
                                        <div className='profilecontainer p-4'>
                                            <h4>Personal Details</h4>
                                            <div className='d-flex'>
                                                <div className='py-2 px-4' style={{ color: "grey" }}>
                                                    <p><strong>Name: </strong></p>
                                                    <p><strong>Joined Date: </strong></p>
                                                    <p><strong>Email ID: </strong></p>
                                                    <p><strong>Mobile: </strong></p>
                                                    <p><strong>Address: </strong></p>
                                                </div>
                                                <div className='py-2 px-4'>
                                                    <p>{userData?.first_name} {userData?.last_name}</p>
                                                    <p>{userData?.date_joined}</p>
                                                    <p>{userData?.email}</p>
                                                    <p>{userData?.phone_number}</p>
                                                    <p>{userData?.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <div className='profilecontainer p-4'>
                                            <h4>Plan / Usage</h4>
                                            <div className='d-flex'>
                                                <div className='py-2 px-4' style={{ color: "grey" }}>
                                                    <p><strong>Name</strong></p>
                                                    <p><strong>Joined Date</strong></p>
                                                    <p><strong>Email ID</strong></p>
                                                </div>
                                                <div className='py-2 px-4'>
                                                    <p>Name</p>
                                                    <p>Joined Date</p>
                                                    <p>Email ID</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <div className="table-responsive">
                                            <table className="table category-tab text-start">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Full Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">User Since</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoices.map(user => (
                                                        <tr key={user.id}>
                                                            <td>#{user.id}</td>
                                                            <td className='text-capitalize'>{user.first_name} {user.last_name}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.date_joined}</td>
                                                            <td>
                                                                <span style={{
                                                                    backgroundColor: user.generation === 'Gold' ? '#fdf3b9' : '#efdac5',
                                                                    borderRadius: '20px',
                                                                    padding: '5px 20px',
                                                                    border: `1px solid ${user.generation === 'Gold' ? '#fcd736' : 'brown'}`
                                                                }}>
                                                                    {user.tier}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <label className="switch">
                                                                    <input type="checkbox" defaultChecked={user.is_active} />
                                                                    {/* <input type="checkbox" defaultChecked={user.is_active} onChange={(e) => handleUserStatusChange(e, user.id)} /> */}
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <button className='icn-btn' >
                                                                    <i className="fa-solid fa-trash-can mx-2"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CustomTabPanel>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Userprofiles;
