import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Chatbot from './Customs/Chatbot';
import Sources from './Customs/Sources';
import Settings from './Customs/Settings';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EmbedSite from './Customs/EmbedSite';
import Customdomain from './Customs/Customdomain';
import { useLocation } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Home({data}) {

    const [value, setValue] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIndex = parseInt(params.get('tab'), 10);
        if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 4) {
            setValue(tabIndex);
        }
    }, [location.search]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Update URL to reflect the current tab
        const params = new URLSearchParams(window.location.search);
        params.set('tab', newValue);
        window.history.replaceState(null, '', `?${params.toString()}`);
    };

    return (
        <div className="main-sec">
            <div className='row'>
                <div className='col-12 col-lg-10 col-xl-8 mainswitch'>
                    <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Playground" {...a11yProps(0)} />
                            <Tab label="Sources" {...a11yProps(1)} />
                            <Tab label="Settings" {...a11yProps(2)} />
                            <Tab label="Embed" {...a11yProps(3)} />
                            {/* <Tab label="Custom Domain" {...a11yProps(4)} /> */}
                        </Tabs>
                    </Box>
                </div>
            </div>
            <CustomTabPanel value={value} index={0}>
                <Chatbot data={data}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Sources data={data}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Settings data={data}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <EmbedSite data={data}/>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={4}>
                <Customdomain data={data}/>
            </CustomTabPanel> */}
        </div>
    );
}

export default Home;
