import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import OpenHeader from '../Components/OpenHeader';

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }


    return (
        <>
            {/* Header */}
            <OpenHeader />
            {/* Header */}
            <div className='privacy-container' style={{margin: '5%'}}>
            <section class="mx-auto flex w-full px-4 prose prose-zinc max-w-4xl flex-col py-20 prose-p:my-2">
                <h1><strong>Privacy Statement</strong></h1>
                <p><strong>Introduction</strong></p>
                <p>nimibot is an AI chatbot builder for sales, customer support, and user engagement.</p>
                <p>We understand that you are aware of and care about your own personal privacy interests, and we take that seriously. This Privacy Notice describes nimibots policies and practices regarding its collection and use of your personal data, and sets forth your privacy rights. We recognize that information privacy is an ongoing responsibility, and so we will from time to time update this Privacy Notice as we undertake new personal data practices or adopt new privacy policies.</p>
                <p><strong>Contacting Us About Data Protection</strong></p>
                <p>nimibot is headquartered in Ontario, Canada. For any inquiries or concerns regarding our personal data policies, practices, or if you wish to exercise your privacy rights, please feel free to reach out directly to our dedicated Privacy Team. Our team is equipped and committed to assisting you with any questions you might have and ensuring your data is handled with the utmost care and respect.</p>
                <p><strong>Privacy Team Contact Information:</strong></p>
                <p>nimibot.co Inc.</p>
                <p>4700 Keele Street, 215 Bergeron Centre, Toronto, ON, Canada, M3J 1P3</p>
                <a href="mailto:privacy@nimibot.co"> privacy@nimibot.co </a>
                <p><strong>How we collect and use process your personal information</strong></p>
                <p>nimibot collects personal information about its website visitors and customers. With a few exceptions, this information is generally limited to:</p>
                <ul>
                    <li>name&nbsp;</li>
                    <li>job title&nbsp;</li>
                    <li>employer name&nbsp;</li>
                    <li>work address&nbsp;</li>
                    <li>work email&nbsp;</li>
                    <li>work phone number&nbsp;</li>
                </ul>
                <p>We use this information to provide prospects and customers with services.&nbsp;</p>
                <p>We do not sell personal information to anyone and only share it with third parties who are facilitating the delivery of our services.</p>
                <p>From time to time, nimibot receives personal information about individuals from third parties. Typically, information collected from third parties will include further details on your employer or industry. We may also collect your personal data from a third party website e.g. LinkedIn&nbsp;</p>
                <p><strong>Use of the nimibot Website</strong></p>
                <p>As is true of most other websites, nimibots website collects certain information automatically and stores it in log files. The information may include internet protocol addresses, the region or general location where your computer or device is accessing the internet, browser type, operating system and other usage information about the use of nimibots website, including a history of the pages you view. We use this information to help us design our site to better suit our users needs. We may also use your IP address to help diagnose problems with our server and to administer our website, analyze trends, track visitor movements, and gather broad demographic information that assists us in identifying visitor preferences.</p>
                <p>nimibot has a legitimate interest in understanding how members, customers and potential customers use its website. This assists nimibot with providing more relevant products and services, with communicating value to our sponsors and corporate members, and with providing appropriate staffing to meet member and customer needs.</p>
                <p><strong>Cookies and tracking technologies</strong><br />nimibot makes available a comprehensive  <a target="_blank" href="/legal/cookie-policy">Cookie Notice</a>  that describes the cookies and tracking technologies used on nimibot website and provides information on how users can accept or reject them. To view the notice, just click  <a target="_blank" href="/legal/cookie-policy">Cookie Notice</a>.</p>
                <p><strong>Sharing information with third parties</strong></p>
                <p>The personal information nimibot collects from you is stored in one or more databases hosted by third parties located in the United States. These third parties do not use or have access to your personal information for any purpose other than cloud storage and retrieval. On occasion, nimibot engages third parties to send information to you, including information about our products, services, and events.</p>
                <p>A list of our third party sub processors can be found here:  <a href="https://trust.nimibot.co/subprocessors" target="_blank">https://trust.nimibot.co/subprocessors</a></p>
                <p>We do not otherwise reveal your personal data to non-nimibot persons or businesses for their independent use unless: 1 you request or authorize it; 2 its in connection with nimibot-hosted and nimibot co-sponsored conferences as described above; 3 the information is provided to comply with the law for example, compelled by law enforcement to comply with a search warrant, subpoena, or court order, enforce an agreement we have with you, or to protect our rights, property or safety, or the rights, property or safety of our employees or others; 4 the information is provided to our agents, vendors or service providers who perform functions on our behalf; 5 to address emergencies or acts of God; or 6 to address disputes, claims, or to persons demonstrating legal authority to act on your behalf. We may also gather aggregated data about our services and website visitors and disclose the results of such aggregated but not personally identifiable information to our partners, service providers, advertisers, and/or other third parties for marketing or promotional purposes.</p>
                <p>The nimibot website connects with third party services such as Facebook, LinkedIn, Twitter and others. If you choose to share information from the nimibot website through these services, you should review the privacy policy of that service. If you are a member of a third party service, the aforementioned connections may allow that service to connect your visit to our site to your personal data.</p>
                <p><strong>Transferring personal data to the U.S.</strong></p>
                <p>Information we collect about you will be processed in the United States. By using nimibots services, you acknowledge that your personal information will be processed in the United States. The United States has not sought nor received a finding of “adequacy” from the European Union under Article 45 of the GDPR. Pursuant to Article 46 of the GDPR, nimibot is providing for appropriate safeguards by entering binding, standard data protection clauses, enforceable by data subjects in the EEA and the UK. These clauses have been enhanced based on the guidance of the European Data Protection Board and will be updated when the new draft model clauses are approved.</p>
                <p>Depending on the circumstance, nimibot also collects and transfers to the U.S. personal data with consent; to perform a contract with you; or to fulfill a compelling legitimate interest of nimibot in a manner that does not outweigh your rights and freedoms. nimibot endeavors to apply suitable safeguards to protect the privacy and security of your personal data and to use it only consistent with your relationship with nimibot and the practices described in this Privacy Statement. nimibot also enters into data processing agreements and model clauses with its vendors whenever feasible and appropriate. Since it was founded, nimibot has received zero government requests for information.</p>
                <p>For more information or if you have any questions, please contact us at  <a href="mailto:privacy@nimibot.co">  privacy@nimibot.co  </a>.</p>
                <p><strong>Data Subject rights</strong></p>
                <p>The European Unions General Data Protection Regulation GDPR and other countries privacy laws provide certain rights for data subjects. Data Subject rights under GDPR include the following:</p>
                <ul>
                    <li>Right to be informed</li>
                    <li>Right of access</li>
                    <li>Right to rectification</li>
                    <li>Right to erasure</li>
                    <li>Right to restrict processing</li>
                    <li>Right of data portability</li>
                    <li>Right to object</li>
                    <li>Rights related to automated decision making including profiling</li>
                </ul>
                <p>This Privacy Notice is intended to provide you with information about what personal data nimibot collects about you and how it is used.&nbsp;</p>
                <p>If you wish to confirm that nimibot is processing your personal data, or to have access to the personal data nimibot may have about you, please contact us.</p>
                <p>You may also request information about: the purpose of the processing; the categories of personal data concerned; who else outside nimibot might have received the data from nimibot; what the source of the information was if you didnt provide it directly to nimibot; and how long it will be stored. You have a right to correct rectify the record of your personal data maintained by nimibot if it is inaccurate. You may request that nimibot erase that data or cease processing it, subject to certain exceptions. You may also request that nimibot cease using your data for direct marketing purposes. In many countries, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how nimibot processes your personal data. When technically feasible, nimibot will—at your request—provide your personal data to you.</p>
                <p>Reasonable access to your personal data will be provided at no cost. If access cannot be provided within a reasonable time frame, nimibot will provide you with a date when the information will be provided. If for some reason access is denied, nimibot will provide an explanation as to why access has been denied.</p>
                <p>For questions or complaints concerning the processing of your personal data, you can email us at  <a href="mailto:privacy@nimibot.co">  privacy@nimibot.co  </a>. Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nations data protection authority.</p>
                <p><strong>Data storage and retention</strong></p>
                <p>Your personal data is stored by nimibot on its servers, and on the servers of the cloud-based database management services nimibot engages, located in the United States. nimibot retains service data for the duration of the customers business relationship with nimibot and for a period of time thereafter, to analyze the data for nimibots own operations, and for historical and archiving purposes associated with nimibots services. nimibot retains prospect data until such time as it no longer has business value and is purged from nimibot systems. All personal data that nimibot controls may be deleted upon verified request from Data Subjects or their authorized agents. For more information on where and how long your personal data is stored, and for more information on your rights of erasure and portability, please contact us at:  <a href="mailto:privacy@nimibot.co">  privacy@nimibot.co  </a></p>
                <p><strong>Childrens data</strong></p>
                <p>We do not knowingly attempt to solicit or receive information from children.</p>
                <p><strong>Questions, concerns or complaints</strong></p>
                <p>If you have questions, concerns, complaints, or would like to exercise your rights, please contact us at:</p>
                <p>nimibot</p>
                <p>4700 Keele Street, 215 Bergeron Centre</p>
                <p>Toronto, ON, Canada, M3J 1P3</p>
                <a href="mailto:privacy@nimibot.co"> privacy@nimibot.co </a>
            </section>
            </div>
        </>
    );
};

export default PrivacyPolicy;
