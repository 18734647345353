import '../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';
import OpenHeader from './OpenHeader';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserApiClient from '../ApiClients';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Dashboard() {
    const [value, setValue] = useState(0);
    const { User } = useContext(UserContext);
    const [planData, setPlanData] = useState({});
    let isFetching = false;

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const navigate = useNavigate();
    const logPage = () => {
        navigate('/login');
    }
    const SignPage = () => {
        navigate('/signup');
    }
    const layoutPage = () => {
        if (User?.is_superuser) {
            navigate('/all-user');
        } else if (!User?.is_superuser) {
            navigate('/chat-bots');
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchData() {
        isFetching = true
        await UserApiClient.get(`bot/plans/get/`, {}, false).then(async res => {
            if (res.success) {
                console.log(res);
                setPlanData(res.data);
                isFetching = false
            }
        })
    }

    useEffect(() => {
        if (!isFetching) fetchData();
    }, [])

    return (

        <div className="container-fluid p-0">
            <div className='dash-background'>
                {/* Header */}
                <OpenHeader />
                {/* Header */}

                <div className='dash-container'>
                    <h1>Custom Ai Chatbot</h1>
                    <h2>Tailored To Your Data</h2>
                    <p>Get instant assistant, answers and more with out AI-powered chatbot.<br /> A smater way to interact and simplify your tasks.</p>
                    {Object.keys(User).length == 0 ? (
                        <button onClick={() => { navigate('/login') }} type="button" className="btn btn-primary">Start Now</button>
                    ) : (
                        <button onClick={layoutPage} type="button" className="btn btn-primary">Start Now</button>
                    )}

                </div>
                <img src="/assets/NimiBot-dashbord.png" className="mobslide" alt="slide2" style={{ width: '100%' }} />

            </div>

            <div className='p-0 mb-5'>
                {/* <img src="/assets/NimiBot-dashbord.png" className="mobslide" alt="logo" style={{ width: '100%' }} /> */}
                <img src="/assets/dashmob2.png" className="deskslide" alt="logo" style={{ width: '100%' }} />
                <img src="/assets/dash-mob.png" className="deskslide" alt="logo" style={{ width: '100%' }} />
            </div>

            <div className='dash-crawsel'>
                <Carousel
                    responsive={responsive}
                    autoPlay
                    autoPlaySpeed={3000}
                    infinite
                    showDots
                    arrows={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/assets/crawsels/NimiBot-Dashboard2.png"
                            style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "20px" }}
                            alt="Dashboard 2"
                        />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/assets/crawsels/NimiBot-Dashboard3.png"
                            style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "20px" }}
                            alt="Dashboard 3"
                        />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/assets/crawsels/NimiBot-Dashboard4.png"
                            style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "20px" }}
                            alt="Dashboard 4"
                        />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/assets/crawsels/NimiBot-Dashboard1.png"
                            style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "20px" }}
                            alt="Dashboard 1"
                        />
                    </div>
                </Carousel>
            </div>

            <div id="features" className='dash-featues'>
                {/* <!-- Add links to CSS frameworks --> */}
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
                <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" />

                {/* <!-- Heading for the section --> */}
                <h1>Features</h1>
                <svg fill="#000000" width="40px" height="40px" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg"><path d="M 26.6875 12.6602 C 26.9687 12.6602 27.1094 12.4961 27.1797 12.2383 C 27.9062 8.3242 27.8594 8.2305 31.9375 7.4570 C 32.2187 7.4102 32.3828 7.2461 32.3828 6.9648 C 32.3828 6.6836 32.2187 6.5195 31.9375 6.4726 C 27.8828 5.6524 28.0000 5.5586 27.1797 1.6914 C 27.1094 1.4336 26.9687 1.2695 26.6875 1.2695 C 26.4062 1.2695 26.2656 1.4336 26.1953 1.6914 C 25.3750 5.5586 25.5156 5.6524 21.4375 6.4726 C 21.1797 6.5195 20.9922 6.6836 20.9922 6.9648 C 20.9922 7.2461 21.1797 7.4102 21.4375 7.4570 C 25.5156 8.2774 25.4687 8.3242 26.1953 12.2383 C 26.2656 12.4961 26.4062 12.6602 26.6875 12.6602 Z M 15.3438 28.7852 C 15.7891 28.7852 16.0938 28.5039 16.1406 28.0821 C 16.9844 21.8242 17.1953 21.8242 23.6641 20.5821 C 24.0860 20.5117 24.3906 20.2305 24.3906 19.7852 C 24.3906 19.3633 24.0860 19.0586 23.6641 18.9883 C 17.1953 18.0977 16.9609 17.8867 16.1406 11.5117 C 16.0938 11.0899 15.7891 10.7852 15.3438 10.7852 C 14.9219 10.7852 14.6172 11.0899 14.5703 11.5352 C 13.7969 17.8164 13.4687 17.7930 7.0469 18.9883 C 6.6250 19.0821 6.3203 19.3633 6.3203 19.7852 C 6.3203 20.2539 6.6250 20.5117 7.1406 20.5821 C 13.5156 21.6133 13.7969 21.7774 14.5703 28.0352 C 14.6172 28.5039 14.9219 28.7852 15.3438 28.7852 Z M 31.2344 54.7305 C 31.8438 54.7305 32.2891 54.2852 32.4062 53.6524 C 34.0703 40.8086 35.8750 38.8633 48.5781 37.4570 C 49.2344 37.3867 49.6797 36.8945 49.6797 36.2852 C 49.6797 35.6758 49.2344 35.2070 48.5781 35.1133 C 35.8750 33.7070 34.0703 31.7617 32.4062 18.9180 C 32.2891 18.2852 31.8438 17.8633 31.2344 17.8633 C 30.6250 17.8633 30.1797 18.2852 30.0860 18.9180 C 28.4219 31.7617 26.5938 33.7070 13.9140 35.1133 C 13.2344 35.2070 12.7891 35.6758 12.7891 36.2852 C 12.7891 36.8945 13.2344 37.3867 13.9140 37.4570 C 26.5703 39.1211 28.3281 40.8321 30.0860 53.6524 C 30.1797 54.2852 30.6250 54.7305 31.2344 54.7305 Z"></path></svg>
                {/* <!-- Container for feature cards --> */}
                <div className='feature-container'>
                    <div className='row'>
                        {/* <!-- Feature Card 1 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash'>
                                <i className="fa-solid fa-2xl fa-fingerprint mb-2"></i>
                                <h2>Privacy & Security</h2>
                                <h6>Your data is hosted on secure servers with robust encryption and access control.</h6>
                            </div>
                        </div>

                        {/* <!-- Feature Card 2 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash'>
                                <i className="fa-solid fa-2xl fa-tag mb-2"></i>
                                <h2>Whitelabel</h2>
                                <h6>Remove NimiBot branding and use a custom domain.</h6>
                            </div>
                        </div>

                        {/* <!-- Feature Card 3 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash '>
                                <svg fill="#000000" width="40" height="40" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.555 8.42c-1.355 1.647-5.070 6.195-8.021 9.81l-3.747-3.804c3.389-3.016 7.584-6.744 9.1-8.079 2.697-2.377 5.062-3.791 5.576-3.213 0.322 0.32-0.533 2.396-2.908 5.286zM18.879 19.030c-1.143 1.399-2.127 2.604-2.729 3.343l-4.436-4.323c0.719-0.64 1.916-1.705 3.304-2.939l3.861 3.919zM15.489 23.183v-0.012c-2.575 9.88-14.018 4.2-14.018 4.2s4.801 0.605 4.801-3.873c0-4.341 4.412-4.733 4.683-4.753l4.543 4.427c0 0.001-0.009 0.011-0.009 0.011z" fill="#fff"></path>
                                </svg>
                                <h2>Customizations</h2>
                                <h6>Customize your chatbot's look and feel to match your brand's style and website design.</h6>
                            </div>
                        </div>

                        {/* <!-- Feature Card 4 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash'>
                                <svg fill="#000000" width="45" height="45" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm8.14-22.086l1.607-1.594c.38-.376.301-1.064-.174-1.535-.475-.472-1.17-.549-1.549-.172l-3.016 2.99-.103.103-2.628 2.605a4.56 4.56 0 00-5.867.45 4.464 4.464 0 00-.454 5.817l-2.006 1.99a7.844 7.844 0 01-1.189-2.293c-.673-2.038-.492-4.27.6-6.11 1.703-2.87 4.75-4.118 7.638-3.752a1.07 1.07 0 001.216-.986 1.307 1.307 0 00-1.174-1.374c-2.901-.297-5.9.65-8.115 2.846-3.67 3.638-3.885 9.409-.66 13.338l-1.84 1.825c-.363.36-.273 1.035.203 1.506.475.471 1.155.562 1.518.202l1.981-1.964h.001l1.432-1.42.003-.004 2.322-2.302a4.558 4.558 0 004.92-.976 4.463 4.463 0 00.985-4.879l2.634-2.611c1.931 2.908 1.548 6.934-1.11 9.568a7.886 7.886 0 01-6.13 2.279.978.978 0 00-1.03.888l-.028.32a1.038 1.038 0 00.991 1.13 10.306 10.306 0 007.832-2.996c3.597-3.567 4.013-9.061 1.19-12.89zm-7.001 7.535a2.18 2.18 0 01-3.06 0 2.135 2.135 0 010-3.033 2.179 2.179 0 013.06 0 2.135 2.135 0 010 3.033z" fill="#fff"></path></svg>
                                <h2>Multiple Data Sources</h2>
                                <h6>Import data from multiple sources to train your chatbot. No more generic answers.</h6>
                            </div>
                        </div>

                        {/* <!-- Feature Card 5 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash '>
                                <svg fill="#000000" width="45" height="45" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M195.62451,151.50684l-52.15967,19.21679a7.99807,7.99807,0,0,0-4.74121,4.741l-19.21679,52.15991a8.00009,8.00009,0,0,1-15.01368,0L85.27637,175.4646a7.99807,7.99807,0,0,0-4.74121-4.741L28.37549,151.50684a8.00019,8.00019,0,0,1,0-15.01368l52.15967-19.21679a7.99807,7.99807,0,0,0,4.74121-4.741l19.21679-52.15991a8.00009,8.00009,0,0,1,15.01368,0l19.21679,52.15991a7.99807,7.99807,0,0,0,4.74121,4.741l52.15967,19.21679A8.00019,8.00019,0,0,1,195.62451,151.50684Z" opacity="0.2" fill="#fff"></path>
                                    <path d="M198.39062,128.98633l-52.16015-19.2168-19.2168-52.16015a16.00075,16.00075,0,0,0-30.02734,0l-19.2168,52.16015-52.16015,19.2168a16.00075,16.00075,0,0,0,0,30.02734l52.16015,19.2168,19.2168,52.16015a16.00075,16.00075,0,0,0,30.02734,0l19.2168-52.16015,52.16015-19.2168a16.00075,16.00075,0,0,0,0-30.02734ZM140.7002,163.2168a15.97421,15.97421,0,0,0-9.4834,9.48242l-19.21973,52.15137L92.7832,172.7002a15.97165,15.97165,0,0,0-9.48242-9.4834L31.14941,143.99707,83.2998,124.7832a15.97165,15.97165,0,0,0,9.4834-9.48242l19.21973-52.15137L131.2168,115.2998a15.97165,15.97165,0,0,0,9.48242,9.4834l52.15137,19.21973ZM144,40a8.00039,8.00039,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8.00039,8.00039,0,0,1,144,40ZM248,88a8.00039,8.00039,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8.00039,8.00039,0,0,1,248,88Z" fill="#fff"></path>
                                </svg>
                                <h2>Powerful AI Models</h2>
                                <h6>Choose from a variety of AI models, including GPT-3.5-turbo and GPT-4.</h6>
                            </div>
                        </div>

                        {/* <!-- Feature Card 6 --> */}
                        <div className='col-md-4'>
                            <div className='inner-dash '>
                                <i className="fa-solid fa-2xl fa-puzzle-piece mb-3"></i>
                                <h2>Integrations</h2>
                                <h6>Connect your chatbot to your favorite tools like Slack, WhatsApp, Zapier, and more.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="price">
                <div className="dash-plan">
                    <h1>The Plans</h1>
                    <div className="main-sec mt-5">
                        <div className='row justify-content-center'>
                            <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                <div className='mainswitch'>
                                    <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                            <Tab label="Monthly" {...a11yProps(0)} />
                                            <Tab label="Yearly" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <CustomTabPanel value={value} index={0}>
                            <div className="row justify-content-between inner-plan">
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>Free <br /><h3 className='mt-2 mb-0'>$ 0.00</h3></h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>20 Messages / month</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>400,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Team Member</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>4 Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Basic Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Website Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Get Started</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.bronze_monthly_plan || 'Plan'} <br /><h3 className='mt-2 mb-0'>$ {planData.bronze_monthly_price || "0.00"} <span>  /Month</span></h3></h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>200 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>15 Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Standard Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.silver_monthly_plan || 'Plan'} <br /><h3 className='mt-2 mb-0'>$ {planData.silver_monthly_price || "0.00"} <span>  /Month</span></h3></h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>1,000 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.gold_monthly_plan || 'Plan'} <br /><h3 className='mt-2 mb-0'>$ {planData.gold_monthly_price || "0.00"} <span>  /Month</span></h3></h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>5,000 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-1"></i>10,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <div className="row justify-content-between inner-plan">
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>Free <br /><h3 className='mt-2 mb-0'>$ 0.00</h3></h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>20 Messages / month</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>400,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Team Member</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>4 Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Basic Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1 Website Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Get Started</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.bronze_yearly_plan || "Plan"} <br /><h3 className='mt-2 mb-0'>$ {planData.bronze_yearly_price || "0.00"} <span>  /Year</span></h3>
                                            <span className='discountimg'><img src="/assets/discount15.png" className="logo mixblend" alt="logo" /></span>
                                        </h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>200 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>1,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>15 Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Standard Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>2 Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.silver_yearly_plan || "Plan"} <br /><h3 className='mt-2 mb-0'>$ {planData.silver_yearly_price || "0.00"} <span>  /Year</span></h3>
                                            <span className='discountimg'><img src="/assets/discount15.png" className="logo mixblend" alt="logo"/></span>
                                        </h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>1,000 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>5 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='plancontain p-0' >
                                        <h4 className='planD'>{planData.gold_yearly_plan || "Plan"} <br /><h3 className='mt-2 mb-0'>$ {planData.gold_yearly_price || "0.00"} <span>  /Year</span></h3>
                                            <span className='discountimg'><img src="/assets/discount15.png" className="logo mixblend" alt="logo"/></span>
                                        </h4>
                                        <div className='d-flex'>
                                            <div className='col-12'>
                                                <h6><i class="fa-solid fa-check me-2"></i>5,000 Messages</h6>
                                                <h6><i class="fa-solid fa-check me-1"></i>10,000,000 Embedding Characters</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Team Members</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>10 Chatbots</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                <h6><i class="fa-solid fa-check me-2"></i>Unlimited Websites Embedded On</h6>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary w-75 my-2">Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                    </div>
                </div>
            </div>

            <div className='dashbgbottom'>
                <div id="contact-us">
                    <div className='dash-contact'>
                        <h1>Join the future of AI chatbots today</h1>
                        <NavLink type="button" to='/signup' className="btn btn-primary px-5 py-2 mb-3">Try for Free <i class="fa-solid fa-arrow-right-long"></i></NavLink>
                    </div>
                </div>

                <div className='dash-footer'>
                    <img src="/assets/Layer_1.png" className="logo me-5" alt="logo" style={{ width: '15%' }} />
                    <div className='newsletter'>
                        <p>JOIN OUR NEWS LETTER</p>
                        <div style={{ display: "flex" }}>
                            <input type="email" className="form-control dash-mail" placeholder="Enter your email" />
                            <button type="button" className="btn btn-primary px-3 mx-2">Join</button>
                        </div>
                    </div>
                </div>
                <div className='copyrightbox'>
                    <p className='m-0 py-5'><i className="fa-regular fa-copyright me-2"></i>NimiBot 2024, Copyright Reserved</p>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
}

export default Dashboard;