import React, { useState, useEffect, createContext } from 'react';
import UserApiClient from './ApiClients';

// Create a context
const UserContext = createContext();


// Create a provider component
const UserProvider = ({ children }) => {

  const [User, setUser] = useState({});
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  let isFetching = false;

  // Your API call to fetch user data
  const fetchData = async () => {
    isFetching = true
    try {
      const res = await UserApiClient.get(`api/user/${userId}/`, {}, true);
      const status = res.success;
      if (status) {
        setUser(res.data);
      }
      isFetching = false
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };


  useEffect(() => {
    if (userId && !User.id && !!token && !isFetching) {
      fetchData();
    }
  }, []);

  return (
    // Wrap your component tree with the context provider
    <UserContext.Provider value={{ User, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export {UserProvider, UserContext};