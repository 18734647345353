import '../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';

function AdminDashboard() {

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };


    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>
                        <div className="main-sec mt-5">
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='p-3 outer'>
                                        <div className='d-flex justify-content-between'>
                                            <span class="dash-widget-icon text-success">
                                                <i class="fa-regular fa-credit-card"></i>
                                            </span>
                                            <h3>23</h3>
                                        </div>
                                        <h6 className='my-3'>User</h6>
                                        <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar bg-success" style={{ width: "25%" }}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className='p-3 outer'>
                                    <div className='d-flex justify-content-between'>
                                        <span class="dash-widget-icon text-warning border-warning">
                                            <i class="fa-regular fa-folder"></i>
                                        </span>
                                        <h3>$ 892.0</h3>
                                        </div>
                                        <h6 className='my-3'>Revenue</h6>
                                        <div class="progress" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar bg-warning" style={{ width: "75%" }}></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default AdminDashboard;
