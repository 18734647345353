import Swal from "sweetalert2";
import UserApiClient from "../../../ApiClients";
import "../../style.css"
import React, { useEffect, useState } from 'react';


function SeoContent() {
    let isFetching = false;
    const UUID = 1;
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    const [data, setData] = useState({
        // id: '',
        // header: '',
        // body: '',
        // footer: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    // Your API call to fetch data
    const fetchData = async () => {
    isFetching = true
    try {
        const res = await UserApiClient.get(`bot/configuration/${UUID}/`, {}, true);
        if (res.success) {
            setData(res.data);
        }
        isFetching = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    const handleFromSubmit = async (e) => {
        e.preventDefault();

        // Check if input contains valid HTML
        if (!htmlRegex.test(data.header) || !htmlRegex.test(data.body) || !htmlRegex.test(data.footer)) {
            Swal.fire('Please add valid tag for all fields.', '', 'warning', 3000);
            return;
        }

        try {
            const res = await UserApiClient.post(`bot/configuration/${UUID}/`, {...data}, true);
            if (res.success) {
                Swal.fire('SCO Content Updated.', '', 'success', 3000);
                setData(res.data);
            }
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };
    useEffect(()=>{
        if (!isFetching) fetchData();
    }, [])

    return (
        <>
            <div className='row'>
                <div className='col-10'>
                    <div className='account-setting'>
                        <form onSubmit={handleFromSubmit}>
                            <div className="col mb-3">
                                <label for="header">Head</label>
                                <textarea className="form-control" id="header" name="header" value={data.header} onChange={handleChange} rows="3" required></textarea>
                            </div>
                            <div className="col mb-3">
                                <label for="body">Body</label>
                                <textarea className="form-control" id="body" name="body" value={data.body} onChange={handleChange} rows="3" required></textarea>
                            </div>
                            <div className="col mb-3">
                                <label for="footer">Footer</label>
                                <textarea className="form-control" id="footer" name="footer" value={data.footer} onChange={handleChange} rows="3" required></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mt-5 mx-3" style={{width: "98%"}}>Save</button>
                        </form>
                    </div>
                </div>

            </div>

        </>
    );
}

export default SeoContent;
