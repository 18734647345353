import '../../Admin/style.css';
import React, { useContext, useState } from 'react';
function Payment() {
    return (
        <>
            <div className='payment-container'>
                <div className='row justify-content-evenly'>
                    <div className='col-3'>
                        <div className='paymethods'>
                        <img src="/assets/mastercard.png" className="logo mb-3" alt="logo"  style={{width: "30%"}}/>
                            <h5><strong>1234 1234 1234 1234</strong></h5>
                            <div className='d-flex justify-content-between'>
                                <div className=''>Expiry date</div>
                                <div className=''>Name: Kumar</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='paymethods'>
                        <img src="/assets/visa.png" className="logo mb-3" alt="logo"  style={{width: "30%"}}/>
                            <h5><strong>1234 1234 1234 1234</strong></h5>
                            <div className='d-flex justify-content-between'>
                                <div className=''>Expiry date</div>
                                <div className=''>Name: Kumar</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='paymethods'>
                        <img src="/assets/Gpay.png" className="logo mb-3" alt="logo"  style={{width: "30%"}}/>
                            <h5><strong>1234 1234 1234 1234</strong></h5>
                            <div className='d-flex justify-content-between'>
                                <div className=''>Expiry date</div>
                                <div className=''>Name: Kumar</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
export default Payment;