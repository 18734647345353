import '../App.css';
import React, { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import UserApiClient from '../ApiClients';
// import loader from '../Configs/loader';
import { UserContext } from '../TabContextProvider';
import { BaseApiUrl } from '../Configs/enviroment';
import GoogleSignIn from './LoginWithGoogle';
// import Swal from 'sweetalert2';

function Login() {
  // const {User, setUser} = useContext(UserContext);
  const { setUser} = useContext(UserContext);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await UserApiClient.post('api/login/', formData).then( async res =>{
      let data = await res.data
      if (res.success && data) {
        setUser(data.user);
        localStorage.setItem('token', data.access);
        localStorage.setItem('userId', data.user.id);
        navigate('/chat-bots');
        // await Swal.fire({
        //   title: "Welcome, Sign In Successfully.",
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      } else {
        console.log(data.detail)
      }
    })
  };

  return (
    <>
      <div className="row user-login">
        <div className='col-sm-0 col-md-3 col-lg-5 col-xl-6 logo-set'>
        <div className='backarrow'><i className="fa-solid fa-circle-arrow-left my-4 mx-5" onClick={()=>navigate('/')} style={{ fontSize: '24px', color: "#47a4e5", cursor: "pointer"}}></i></div>
          <div className='logo-img'><img src="/assets/Layer_1.png" className="logo" onClick={()=>navigate('/')} alt="logo" style={{width: "50%", cursor: "pointer"}}/></div>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-7 col-xl-6 login-form'>
          <div className='signin-set'>
          <div className='signin'>
            <h4>Sign in</h4>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <input type="email" className="form-control" name='email' onChange={handleChange} placeholder="Enter email" required/>
              </div>
              <div className="form-group">
                <input type="password" className="form-control" name='password' onChange={handleChange} placeholder="Password" required />
              </div>
              <div className='forgetpass'><NavLink to='/forget-pass'>Forget Password?</NavLink></div>
              <button type="submit" className="signin-btn btn btn-primary">Sign In</button>
              <div className='orline'><hr style={{ width: '40%' }} ></hr><p className='m-0'>or</p><hr style={{width:'40%'}}></hr></div>
              <div className=''>
                <GoogleSignIn />
                <small id="emailHelp" className="form-text text-muted">Protected by reCAPTCHA and Subject to the Nimibot <NavLink to='/privacy-policy'>Privacy Policy</NavLink> and <NavLink to='/terms-of-service'>Terms of service</NavLink></small>
              </div>
            </form>

          </div>
          <div className='mt-4' style={{ textAlign: 'center' }}><h6>Don't have an account? 
            <NavLink to='/signup'> Sign up</NavLink></h6></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
